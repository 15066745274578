import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from "../components/Homepage.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`The Band`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`Passenger Lounge is a three-piece musical act based out of Louisiana.`}</p>
      <p>{`Jesse is on `}<inlineCode parentName="p">{`vocals`}</inlineCode>{`, Alec is on `}<inlineCode parentName="p">{`vocals`}</inlineCode>{`, `}<inlineCode parentName="p">{`guitar`}</inlineCode>{`, `}<inlineCode parentName="p">{`bass guitar`}</inlineCode>{`, `}<inlineCode parentName="p">{`keyboard`}</inlineCode>{` and `}<inlineCode parentName="p">{`drums`}</inlineCode>{`, and Peter is on `}<inlineCode parentName="p">{`vocals`}</inlineCode>{`, `}<inlineCode parentName="p">{`bass guitar`}</inlineCode>{`,
`}<inlineCode parentName="p">{`keyboard`}</inlineCode>{`, `}<inlineCode parentName="p">{`drum machine`}</inlineCode>{`, and `}<inlineCode parentName="p">{`saxophone`}</inlineCode>{`.`}</p>
    </PageDescription>
    <FeatureCard color="dark" href="/discography/viscous" title="Latest Release" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRlAAAABXRUJQVlA4IEQAAABQAwCdASoUAAoAPtFUo0uoJKMhsAgBABoJagAASoS5khQ+EAD+8a/6mW459TJfSf/1S6f+nnr+z4B58fFxgztHlNxAAA==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/7e2f76fb9dac148ea3af9d5ac7ddcd83/e0ca3/blue-stripes-mobile.webp",
            "srcSet": ["/static/7e2f76fb9dac148ea3af9d5ac7ddcd83/0eda2/blue-stripes-mobile.webp 288w", "/static/7e2f76fb9dac148ea3af9d5ac7ddcd83/460e2/blue-stripes-mobile.webp 576w", "/static/7e2f76fb9dac148ea3af9d5ac7ddcd83/e0ca3/blue-stripes-mobile.webp 1152w", "/static/7e2f76fb9dac148ea3af9d5ac7ddcd83/134ac/blue-stripes-mobile.webp 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRlIAAABXRUJQVlA4IEYAAABwAwCdASoUAAcAPtFUpEuoJKOhsAgBABoJZACdMoADQIFmuQAA/unMw8r8lbSh/Jy939JLZ3zoya3KAb8mKD03vqnoAAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/96cbca18eeac53644872c1962fa3152d/e0ca3/blue-stripes-tablet.webp",
            "srcSet": ["/static/96cbca18eeac53644872c1962fa3152d/0eda2/blue-stripes-tablet.webp 288w", "/static/96cbca18eeac53644872c1962fa3152d/460e2/blue-stripes-tablet.webp 576w", "/static/96cbca18eeac53644872c1962fa3152d/e0ca3/blue-stripes-tablet.webp 1152w", "/static/96cbca18eeac53644872c1962fa3152d/d02be/blue-stripes-tablet.webp 1728w", "/static/96cbca18eeac53644872c1962fa3152d/e0aab/blue-stripes-tablet.webp 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/webp;base64,UklGRlIAAABXRUJQVlA4IEYAAABwAwCdASoUAAcAPtFUpEuoJKOhsAgBABoJZACdMoADQIFmuQAA/unMw8r8lbSh/Jy939JLZ3zoya3KAb8mKD03vqnoAAAA')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "green vertical stripes",
            "title": "green vertical stripes",
            "src": "/static/96cbca18eeac53644872c1962fa3152d/e0ca3/blue-stripes.webp",
            "srcSet": ["/static/96cbca18eeac53644872c1962fa3152d/0eda2/blue-stripes.webp 288w", "/static/96cbca18eeac53644872c1962fa3152d/460e2/blue-stripes.webp 576w", "/static/96cbca18eeac53644872c1962fa3152d/e0ca3/blue-stripes.webp 1152w", "/static/96cbca18eeac53644872c1962fa3152d/d02be/blue-stripes.webp 1728w", "/static/96cbca18eeac53644872c1962fa3152d/e0aab/blue-stripes.webp 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      