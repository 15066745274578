import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { TextInput, TextArea, Button } from "carbon-components-react";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
  Contact the band for any booking, media/press, licensing, and/or general
  inquiries
    </PageDescription>
    <form name="contact" method="POST" data-netlify="true" netlifyHoneypot="bot-field">
  <input type="hidden" name="form-name" value="contact" />
      <p style={{
        display: "none"
      }}>
  <label>
    Don’t fill this out if you're human: <input name="bot-field" />
  </label>
      </p>
      <TextInput id="name" name="name" labelText="Name" placeholder="Your name" required style={{
        marginBottom: "1rem"
      }} mdxType="TextInput" />
      <TextInput id="email" name="email" type="email" labelText="Email" placeholder="your@email.com" required style={{
        marginBottom: "1rem"
      }} mdxType="TextInput" />
      <TextArea id="inquiry" name="inquiry" labelText="Inquiry" placeholder="Write your message here..." rows={3} required style={{
        marginBottom: "1rem"
      }} mdxType="TextArea" />
  <Button type="submit" style={{
        display: "inline-block",
        backgroundColor: "#009149",
        border: "1px solid #009149",
        borderRadius: "8px",
        color: "#fff",
        fontWeight: 600,
        fontSize: "14px",
        fontFamily: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, ' + 'Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
        padding: "0 20px",
        height: "40px",
        marginTop: 0,
        cursor: "pointer"
      }} mdxType="Button">
      Send
  </Button>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      